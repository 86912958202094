import PageWrapper from '../src/containers/PageWrapper';
import { API_KEYS } from '../src/__constants__';
import PageHead from '../src/components/PageHead';

const CONTENT = [
    {
        url: 'https://vouchedfor.co.uk/?utm_source=VF404page',
        title: 'Search Our Directory',
        content: 'Looking for a financial adviser, legal adviser or accountant? Search for one local to you and read reviews from other clients on our directory',
        colorClass: 'bg-mint-green',
    },
    {
        url: 'https://discover.vouchedfor.co.uk/?utm_source=VF404page',
        title: 'Let Us Suggest an IFA For You',
        content: "Not sure where to start? Simply tell us a bit about what you're looking for and we'll match you with up to 3 advisers for a free, no obligation chat",
        colorClass: 'bg-primary-blue',
    },
    {
        url: 'https://mortgages.vouchedfor.co.uk/?utm_source=404page',
        title: 'Get a Free Mortgage Quote',
        content: 'Looking for mortgage advice? Simply fill in our form to get free quotes from up to 3 mortgage advisers',
        colorClass: 'bg-cherry-red',
    },
];

export function ErrorPage(props) {
    const renderItem = v => {
        return (
            <div className={'py-0 px-2 grow-0 w-full sm:max-w-[30%] sm:basis-[30%] md:max-w-[20%] md:basis-1/5'} key={v.title}>
                <a
                    href={v.url}
                    className={`decoration-0 no-underline block h-full transition-opacity opacity-100 hover:opacity-60 ${v.colorClass}`}
                >
                    <div className={'min-h-[5em] flex justify-center items-center'}>
                        <h6 className={'py-4 px-12 text-white text-[1.1rem] m-0 leading-6'}>
                            {v.title}
                        </h6>
                    </div>
                    <hr className={'mx-4 bg-white border-0 h-[1px] m-0'} />
                    <p className={'py-8 px-12 text-white text-[1.1rem] m-0 leading-6'}>
                        {v.content}
                    </p>
                </a>
            </div>
        );
    }

    const { urls } = props;

    return (
        <PageWrapper urls={urls}>
             <PageHead disableBots>
                <meta name="prerender-status-code" content="404" />
            </PageHead>
            <div className='py-6 text-center'>
                <h1 className={'text-[2.125rem] font-bold leading-[1.235] m-0'}>Sorry!</h1>
                <h2 className={'text-xl font-bold leading-[1.6] m-0'}>We can't seem to find the page you're looking for.</h2>

                <div className={'mt-14 mb-12'}>
                    <p className={'text-[1.1rem] m-0 leading-6'}>Here are some helpful links instead:</p>
                    <div className={'mt-6'}>
                        <div className={'flex flex-col sm:flex-row gap-4 justify-center flex-wrap w-full box-border -m-2'}>
                            { CONTENT.map(renderItem) }
                        </div>
                    </div>
                </div>

                <p className={'text-[1.1rem] m-0 leading-6'}>
                    Or, if you're a VouchedFor professional, <a href={`${API_KEYS.ACCOUNTS_URL}/login`}>Sign in here</a>
                </p>
            </div>
        </PageWrapper>
    );
}

export default ErrorPage;
